import { template as template_200ed389ce3d49599776d531089418f4 } from "@ember/template-compiler";
const FKLabel = template_200ed389ce3d49599776d531089418f4(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
